import CartContainer from '../../components/CartContainer/CartContainer';

function CartPage() {
  return (
    <>
      <CartContainer />
    </>
  );
}

export default CartPage;
